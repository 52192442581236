<script>
import * as invoiceService from '@/services/invoice'

export default {
  created() {
    this.successModal()
  },
  mounted() {
    console.log(this.$route.query)
  },
  methods: {
    successModal() {
      this.$swal({
        title: 'Congratulation!',
        text: 'Please click Okay button to continue',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Okay',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.commit('set_loading_action', true)
          const parseUrl = this.$route.query
          invoiceService.callbackQBO({ parseUrl })
            .then(res => {
              console.log(res)
              this.$store.commit('set_loading_action', false)
              this.$router.push({ name: 'profile' })
            })
            .catch(() => {
              this.$store.commit('set_loading_action', false)
            })
        }
      })
    },
  },
  template: '<div></div>',
}
</script>

<style>

</style>
